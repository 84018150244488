/** ** DO NOT EDIT ****
 * This file is generated by ./bin/update-i18n.ts
 * Any changes made here will be overwritten
 * Run ./bin/update-i18n.ts to update
 ** ** DO NOT EDIT *** */

// @ts-ignore
import { Topic } from "types/Topic";

export default {
  about: () => "/es/quienes-somos",
  author: (uid: string) => `/es/autor/${uid}`,
  babyNames: () => "/es/embarazo/nombres-bebe",
  blogPost: (slug: string) =>
    `/es//${slug}`.replace("//", "/"),
  careers: () => "https://peanut.recruitee.com/",
  chineseGenderPredictor: (results?: string) => {
    let path = `/es/prediccion-genero-chino`;

    if (results) {
      path += `/resultados/${results}`;
    }

    return path;
  },
  community: () => "/es/publicaciones",
  communityPost: (uid: string, slug?: string) =>
    slug
      ? `/es/publicaciones/${uid}/${slug}`
      : `/es/publicaciones/${uid}`,
  download: () => "/es/descargar",
  dueDateCalculator: (results?: string) => {
    let path = `/es/calculadora-salir-cuentas`;

    if (results) {
      path += `/resultados/${results}`;
    }

    return path;
  },
  experts: (category?: string) => {
    let path = `/es/expertas`;
    if (category) {
      path = `${path}/${`experts.category.${category.toLowerCase()}`}`;
    }
    return path;
  },
  group: (uid: string, slug?: string) => {
    let path = `/es/grupos/${uid}`;
    if (slug) {
      path = `${path}/${slug}`;
    }
    return path;
  },
  guidelines: () => "/es/normas-comunidad",
  help: (slug?: string) => {
    const helpSectionMap: { [key: string]: string } = {
      "help-account": "ayuda-cuenta",
      "help-community-safety-and-wellbeing": "ayuda-seguridad",
      "help-connection": "ayuda-contacto",
      "help-feedback-and-support": "ayuda-comentarios",
      "help-pro": "ayuda-pros",
      "help-technical": "ayuda-asistencia-tecnica"
    };

    let path = `/es/ayuda`;
    if (slug) {
      path = `${path}/${helpSectionMap[slug]}`;
    }
    return path;
  },
  home: () => ("/es".length > 0 ? "/es" : "/"),
  privacy: () => "/es/privacidad",
  pregnancyWeekByWeek: () => "/es/pregnancy-week-by-week",
  medicalReview: () => "/es/revision-medica",
  notFound: () => "/es/404",
  offers: () => "/es/editorial/peanut-ofertas",
  ovulationCalculator: (cycle?: string, firstCycleDate?: string) => {
    let path = `/es/calculadora-de-ovulacion`;
    if (cycle && firstCycleDate) {
      path = `resultados/${cycle}/${firstCycleDate}`;
    }
    return path;
  },
  shop: () => "https://shop.peanut-app.io",
  sitemap: () => "/es/mapadelsitio",
  starther: () => "/es/starther",

  topicHub: (topic: Topic) => `/es${topic.path}`,
  peanutAtWork: () => "/es/peanut-at-work",
  peanutNews: () =>
    "/es/de-peanut/blog",
  topicBlogIndex: (topic: Topic, page?: number) => {
    if (!topic) {
      return `/es/blog`;
    }
    let url = `/es${topic.path}/blog`;
    if (page && page > 1) {
      url += `/pagina/${page}`;
    }
    return url;
  },
  topicCommunityIndex: (topic: Topic, page?: number) => {
    let url = `/es${topic.path}/comunidad`;
    if (page && page > 1) {
      url += `/pagina/${page}`;
    }
    return url;
  },

  terms: () => "/es/terminos-y-condiciones",
  ttc: () => "/es/intentando-concebir"
};
