/** ** DO NOT EDIT ****
 * This file is generated by ./bin/update-i18n.ts
 * Any changes made here will be overwritten
 * Run ./bin/update-i18n.ts to update
 ** ** DO NOT EDIT *** */

// @ts-ignore
import { Topic } from "types/Topic";

export default {
  about: () => "/about-us",
  author: (uid: string) => `/author/${uid}`,
  babyNames: () => "/pregnancy/baby-names",
  blogPost: (slug: string) =>
    `//${slug}`.replace("//", "/"),
  careers: () => "https://peanut.recruitee.com/",
  chineseGenderPredictor: (results?: string) => {
    let path = `/chinese-gender-predictor`;

    if (results) {
      path += `/results/${results}`;
    }

    return path;
  },
  community: () => "/posts",
  communityPost: (uid: string, slug?: string) =>
    slug
      ? `/posts/${uid}/${slug}`
      : `/posts/${uid}`,
  download: () => "/download",
  dueDateCalculator: (results?: string) => {
    let path = `/due-date-calculator`;

    if (results) {
      path += `/results/${results}`;
    }

    return path;
  },
  experts: (category?: string) => {
    let path = `/experts`;
    if (category) {
      path = `${path}/${`experts.category.${category.toLowerCase()}`}`;
    }
    return path;
  },
  group: (uid: string, slug?: string) => {
    let path = `/groups/${uid}`;
    if (slug) {
      path = `${path}/${slug}`;
    }
    return path;
  },
  guidelines: () => "/community-guidelines",
  help: (slug?: string) => {
    const helpSectionMap: { [key: string]: string } = {
      "help-account": "help-account",
      "help-community-safety-and-wellbeing": "help-community-safety-and-wellbeing",
      "help-connection": "help-connection",
      "help-feedback-and-support": "help-feedback-and-support",
      "help-pro": "help-pro",
      "help-technical": "help-technical"
    };

    let path = `/help`;
    if (slug) {
      path = `${path}/${helpSectionMap[slug]}`;
    }
    return path;
  },
  home: () => ("".length > 0 ? "" : "/"),
  privacy: () => "/privacy",
  pregnancyWeekByWeek: () => "/pregnancy-week-by-week",
  medicalReview: () => "/medical-review",
  notFound: () => "/404",
  offers: () => "/editorial/peanut-offers",
  ovulationCalculator: (cycle?: string, firstCycleDate?: string) => {
    let path = `/ovulation-calculator`;
    if (cycle && firstCycleDate) {
      path = `results/${cycle}/${firstCycleDate}`;
    }
    return path;
  },
  shop: () => "https://shop.peanut-app.io",
  sitemap: () => "/sitemap",
  starther: () => "/starther",

  topicHub: (topic: Topic) => `${topic.path}`,
  peanutAtWork: () => "/peanut-at-work",
  peanutNews: () =>
    "/from-peanut/blog",
  topicBlogIndex: (topic: Topic, page?: number) => {
    if (!topic) {
      return `/blog`;
    }
    let url = `${topic.path}/blog`;
    if (page && page > 1) {
      url += `/page/${page}`;
    }
    return url;
  },
  topicCommunityIndex: (topic: Topic, page?: number) => {
    let url = `${topic.path}/community`;
    if (page && page > 1) {
      url += `/page/${page}`;
    }
    return url;
  },

  terms: () => "/terms",
  ttc: () => "/ttc"
};
